







































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { addOrEditPerson, idCardExists, personDetails, accountExists, userTypes } from '@/api/system/PersonApi';
import { personListReSetPassword } from '@/api/main/MainAPi';
import { getCompanyTreeData, getCompanysByPersonId } from '@/api/system/OrganizationApi';
import DeptAddEdit from './dept-add-edit.vue';
import FileAddEdit from './file-add-edit.vue';
import { checkIDCard } from '@/utils/ValidateUtil';
import DictApi from '@/api/system/DictApi';
// import EleUpload from '@/components/form-design/elements/ele-upload.vue';
import _ from 'lodash';
@Component({
  name: 'PersonForm',
  components: {
    DeptAddEdit,
    FileAddEdit,
    EleUpload: () => import('@/components/form-design/elements/ele-upload.vue')
  }
})
export default class PersonForm extends Vue {
  // 验证邮箱
  validatorEnterpriseEmail(rule, value, callback) {
    if (!value) {
      callback();
    } else if (value) {
      var num = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (!num.test(value)) {
        callback(new Error(this.$t('lang_the_input_mailbox_format_is_incorrect') as any));
      } else {
        callback();
      }
    } else {
      callback();
    }
  }

  //验证手机号
  validatePhone = (rule, value, callback) => {
    if (!value) {
      callback(new Error(this.$t('lang_please_entry_tel_phone') as any));
    } else if (!/^1(2|3|4|5|6|7|8|9)\d{9}$/.test(value)) {
      callback(new Error(this.$t('lang_please_enter_the_mobile_number_in_the_correct_format') as any));
    } else {
      callback();
    }
  };

  // ref
  @Ref() readonly formRef;
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  //   当前数据
  @Prop({ default: undefined }) currtent;
  // 传过来的数据
  @Prop({ default: {} }) selectCompanyData;
  // 公司id
  @Prop({ default: [] }) groupIds;

  get _getPartyAge() {
    let age = Number(new Date().getFullYear()) - Number(new Date(this.personData.regularDate).getFullYear());
    if (age <= 100) {
    } else {
      age = 0;
    }
    let month = Number(new Date().getMonth()) - Number(new Date(this.personData.regularDate).getMonth());
    if (month > 0) {
      return age + '年';
    } else if (month == 0) {
      let day = Number(new Date().getDate()) - Number(new Date(this.personData.regularDate).getDate());
      if (day >= 0) {
        return age + '年';
      } else {
        return age - 1 < 0 ? 0 + '年' : age - 1 + '年';
      }
    } else {
      return age - 1 < 0 ? 0 + '年' : age - 1 + '年';
    }
  }

  get _getAge() {
    let age = Number(new Date().getFullYear()) - Number(new Date(this.personData.birthday).getFullYear());
    if (age <= 100) {
    } else {
      age = 0;
    }
    let month = Number(new Date().getMonth()) - Number(new Date(this.personData.birthday).getMonth());
    if (month > 0) {
      return age + ' 岁';
    } else if (month == 0) {
      let day = Number(new Date().getDate()) - Number(new Date(this.personData.birthday).getDate());
      if (day >= 0) {
        return age + ' 岁';
      } else {
        return age - 1 < 0 ? 0 + ' 岁' : age - 1 + ' 岁';
      }
    } else {
      return age - 1 < 0 ? 0 + ' 岁' : age - 1 + ' 岁';
    }
  }
  // 仅仅作为前端展示数据
  frontDisplayData: any = {};
  // 是否显示新增部门信息弹框
  isShowAddEdit: boolean = false;
  //   表单加载动画
  formLoading: boolean = false;
  isShow: boolean = false;
  //   表单数据
  personData: any = {
    takeOffices: [],
    takeAnnex: []
  };
  laborContract: any = {
    factEmploymentConfirmDate: '',
    employmentConfirmDate: '',
    period: '',
    invalidDate: ''
  };
  nationList: any = [];
  // 当前选中的部门信息
  deptRow: any = {};
  // 公司下拉数据
  companyList: any[] = [];
  // 部门下拉数据
  deptListData: any[] = [];
  // 职务下拉数据
  dutyListData: any[] = [];
  // 岗位下拉数据
  takePostListData: any[] = [];
  // 用户类型列表
  userTypeList: any[] = [];
  // 账号规则
  accountSuffix: string = '';
  // 公司id
  companyId: string = '';
  // 部门编辑时用于编辑
  deptIndex: number = null;
  fileIndex: number = null;

  dayEducationList: any = [];
  dayDegreeList: any = [];

  politicalList: any = [];
  proviceList: any[] = [];
  //   验证规则
  formRules: Record<string, any> = {
    groupId: {
      required: true,
      message: this.$t('lang_please_enter'),
      trigger: 'change'
    },
    userType: {
      required: true,
      message: this.$t('lang_please_select_user_type'),
      trigger: 'change'
    },
    name: {
      required: true,
      message: this.$t('lang_please_enter_your_name'),
      trigger: 'blur'
    },
    sex: {
      required: true,
      message: this.$t('lang_please_select_sex'),
      trigger: 'blur'
    },
    idType: {
      required: true,
      message: this.$t('lang_please_select_document_type'),
      trigger: 'blur'
    },
    birthday: {
      required: true,
      message: this.$t('lang_please_entry_birthday'),
      trigger: 'blur'
    },
    idCard: {
      required: true,
      message: this.$t('lang_please_entry_id_card'),
      trigger: 'blur'
    },
    phone: {
      required: true,
      validator: this.validatePhone,
      trigger: 'blur'
    },
    account: {
      required: true,
      message: this.$t('lang_please_entry_a_account'),
      trigger: 'blur'
    },
    password: {
      validator: this.validatePass,
      trigger: 'change',
      required: false
      // message: this.$t('lang_please_enter_password')
    },
    sort: {
      required: true,
      message: this.$t('lang_form_please_enter_a_sort'),
      trigger: 'blur'
    },
    email: {
      validator: this.validatorEnterpriseEmail,
      trigger: 'blur'
    }
  };

  isShowAddFileEdit: boolean = false;
  fileRow: any = {};
  fileListData: any[] = [];

  expReminderSelect: string = '1';

  // 图片组件上传地址
  private uploadUrl: string = '/river-fs/file/create';
  // 图片组件预览地址
  private pubPreviewUrl: string = '/river-fs/file/view';
  getSelectData() {
    let params1 = {
      typeCode: 'political_outlook'
    };
    DictApi.dictList(params1).then((reg: any) => {
      if (reg.code == '1') {
        this.politicalList = reg.data;
      } else {
        this.$message.error(reg.message);
      }
    });
    let params2 = {
      typeCode: 'education'
    };
    DictApi.dictList(params2).then((reg: any) => {
      if (reg.code == '1') {
        this.dayEducationList = reg.data;
      } else {
        this.$message.error(reg.message);
      }
    });
    let params3 = {
      typeCode: 'degree'
    };
    DictApi.dictList(params3).then((reg: any) => {
      if (reg.code == '1') {
        this.dayDegreeList = reg.data;
      } else {
        this.$message.error(reg.message);
      }
    });
    let params4 = {
      typeCode: 'area_data'
    };
    DictApi.dictTree(params4).then((reg: any) => {
      if (reg.code == '1') {
        this.proviceList = reg.data;
      }
    });
    let params5 = {
      typeCode: 'nation'
    };
    DictApi.dictList(params5).then((reg: any) => {
      if (reg.code == '1') {
        this.nationList = reg.data;
      } else {
        this.$message.error(reg.message);
      }
    });
  }
  changeValue() {
    this.$forceUpdate();
  }
  created() {
    this.companyId = this.groupIds[0];
    this.getUserTypesList();
    this.getDeptGroupSelectList(this.groupIds[0]);
    this.getDutySelectList(this.groupIds[0]);
    this.getTakePostSelectList(this.groupIds[0]);
    this.getSelectData();
    if (this.currtent) {
      this.getPersonDetail();
    } else {
      this.companyList.push(this.selectCompanyData);
      this.personData = {
        tenantId: this.selectCompanyData.tenantId,
        defaultCompanyId: this.selectCompanyData.id,
        takeOffices: [],
        takeAnnex: [{}],
        idType: '1',
        // password: 'Aa123456!',
        user: {
          tenantId: this.selectCompanyData.tenantId
        }
      };
      this.accountSuffix = '@' + this.selectCompanyData.groupCode;
    }
  }
  resetPassword() {
    this.$confirm('确定重置密码?此操作不可撤销', '重置密码', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(action => {
      personListReSetPassword({ id: this.personData.user.id }).then((reg: any) => {
        if (reg.code == 1) {
          this.$alert('重置成功，点击完成关闭此对话框！', '成功', {
            confirmButtonText: '完成',
            callback: () => {}
          });
        } else {
          this.$message.error(reg.message);
        }
      });
    });
  }
  /**
   * 密码验证
   */
  validatePass(rule, value, callback) {
    let passwordReg = /^[A-Za-z0-9\!\@\#\$\%\^\&\*\(\)\-\_\=\+]{8,}$/;
    if (value === '') {
      callback(new Error('请输入密码'));
    } else {
      if (!passwordReg.test(value)) {
        callback(new Error('密码格式不正确'));
      } else {
        callback();
      }
      /*   if (this.personData.password !== '') {
        let form: any = this.$refs.formRef;
        form.validateField('password');
      } */
    }
  }
  /**
   * 获取用户类型列表
   */
  getUserTypesList() {
    userTypes().then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.userTypeList = res.data;
      }
    });
  }

  /**
   * 获取详情
   */
  getPersonDetail() {
    let params = {
      companyId: this.groupIds[0],
      personId: this.currtent.id,
      idCard: this.currtent.idCard
    };
    personDetails(params).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.$nextTick(() => {
          let resData = res.data;
          if (resData && resData.takeAnnex) {
            Object.keys(res.data.takeAnnex).map(keys => {
              if (keys != 'others') resData.takeAnnex[keys] = [resData.takeAnnex[keys]];
            });
            resData.takeAnnex = [resData.takeAnnex];
          }

          this.personData = resData;
          if (res.data.laborContract) {
            this.laborContract = res.data.laborContract;
          }
          if (res.data.user) {
            this.getCompanyList(res.data.id);
            // 用户排序
            this.personData.sort = res.data.user.sort;
            this.personData.yearDays = res.data.user.yearDays;
            // 用户类型
            if (res.data.user.userType) {
              this.personData.userType = res.data.user.userType;
            }
            // 账号
            let aco = res.data.user.account.split('@');
            this.accountSuffix = '@' + aco[1];
            this.$set(this.personData, 'account', aco[0]);
          }
        });
      }
    });
  }

  /**
   * 编辑时获取主公司列表
   */
  getCompanyList(personId) {
    getCompanysByPersonId({ personId: personId }).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        const resData = res.data;
        // 判断返回的数据中是否有当前公司
        const companyData = this.$store.getters.companyData;
        if (Array.isArray(res.data) && companyData && companyData.id) {
          const hasCurrentCompany = res.data.some(item => item.id == companyData.id);
          // 如果没有当前公司，就将当前公司插入进去
          if (!hasCurrentCompany) resData.push(companyData);
        }
        this.companyList = resData;
        this.personData.defaultCompanyId = this.getCompanyId(resData);
      }
    });
  }

  /**
   * 获取主公司id
   */
  getCompanyId(list) {
    for (let i = 0; i < list.length; i++) {
      let a = list[i];
      if (a.defaultAble) {
        return a.id;
      } else {
        if (a.children && a.children.length > 0) {
          let res = this.getCompanyId(a.children);
          if (res) {
            return res;
          }
        }
      }
    }
  }

  /**
   * 账号验证
   */
  accountChange(val) {
    accountExists({ account: this.personData.account + this.accountSuffix }).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.$message.error(this.$t('lang_account_number_already_exists_please_re-enter') as any);
      }
    });
  }

  menuTreeNormalizer(node) {
    let params = {
      id: node.id,
      label: node.name,
      children: node.children
    };
    return params;
  }

  /**
   * 新增部门职务
   */
  deptAddClick(row, index) {
    this.deptRow = row;
    this.frontDisplayData.employmentDate = this.laborContract.employmentDate;
    if (row) {
      this.deptIndex = index;
    }
    this.isShowAddEdit = true;
  }

  /**
   * 新增附件信息
   */
  fileAddClick(row, index) {
    this.fileRow = row;
    if (row) {
      this.fileIndex = index;
    } else if (this.personData.takeAnnex && this.personData.takeAnnex.length) {
      this.$message.error('附件信息仅能有一条');
      return;
    }
    this.isShowAddFileEdit = true;
  }

  /**
   * 关闭部门信息弹框
   */
  deptClose(data) {
    if (data.defaultAble) {
      this.personData.takeOffices.forEach(item => {
        item.defaultAble = false;
      });
    }
    if (this.deptRow) {
      this.personData.takeOffices.splice(this.deptIndex, 1, data);
    } else {
      this.personData.takeOffices.push(data);
    }
  }
  /**
   * 关闭部门信息弹框
   */
  fileClose(data) {
    if (data) {
      if (!this.personData.takeAnnex) this.personData.takeAnnex = [];
      let takeAnnexItem = {};
      Object.keys(data).map(keys => {
        let item = data[keys];
        takeAnnexItem[keys] = item;
      });
      this.personData.takeAnnex.push(takeAnnexItem);
    }
  }
  upFileClose(data) {
    this.personData.takeAnnex.pop();
    let takeAnnexItem = {};
    Object.keys(data).map(keys => {
      let item = data[keys];
      takeAnnexItem[keys] = item;
    });
    this.personData.takeAnnex.push(takeAnnexItem);
  }

  /**
   * 证件号改变验证
   */
  idCardChange(idCard) {
    let reg = /[\u4E00-\u9FA5\uF900-\uFA2D]/;
    if (reg.test(idCard)) {
      this.$message.error(this.$t('lang_the_ID_number_cannot_be_in_Chinese') as any);
      return;
    }
    /**
     * 验证身份证是否正确
     */
    if (!checkIDCard(idCard)) {
      return this.$message.error(this.$t('lang_the_ID_number_entered_is_incorrect_please_re_enter_it') as any);
    } else {
      if (this.personData.idType == '1') {
        //获取出生日期
        let birthday = idCard.substring(6, 10) + '-' + idCard.substring(10, 12) + '-' + idCard.substring(12, 14);
        this.$set(this.personData, 'birthday', birthday);
      }

      /**
       * 判断证件号是否存在,如果有带出来
       */
      let params = {
        companyId: this.groupIds[0],
        idCard: idCard,
        personId: ''
      };
      personDetails(params).then((res: Record<any, any>) => {
        if (res.code == '1') {
          if (JSON.stringify(res.data) != '{}') {
            this.$nextTick(() => {
              this.$message.warning(this.$t('lang_this_certificate_number_already_exists') as any);
              this.personData = res.data;
              if (JSON.stringify(res.data.takeAnnex) == '{}') {
                this.personData.takeAnnex = [{}];
              }
              this.laborContract = res.data?.laborContract;
              if (res.data.user) {
                this.getCompanyList(res.data.id);
                // 用户排序
                this.personData.sort = res.data.user.sort;
                this.personData.yearDays = res.data.user.yearDays;
                // 用户类型
                if (res.data.user.userType) {
                  this.personData.userType = res.data.user.userType;
                }
                // 账号
                let aco = res.data.user.account.split('@');
                this.accountSuffix = '@' + aco[1];
                this.$set(this.personData, 'account', aco[0]);
              }
            });
          }
        }
      });
    }
  }

  /**
   * 删除部门职务
   */
  removeDept(row, index) {
    if (row.id) {
      this.$message.error(this.$t('lang_current_information_cannot_be_deleted') as any);
    } else {
      this.personData.takeOffices.splice(index, 1);
    }
  }

  /**
   * 删除附件信息
   */
  removeFile() {
    this.personData.takeAnnex.pop();
    this.personData.takeAnnex = [{}];
  }

  // 获取部门下拉列表
  getDeptGroupSelectList(groupIds) {
    getCompanyTreeData({ parentId: groupIds, types: ['0', '1'], cascadeType: '2' })
      .then((res: Record<any, any>) => {
        this.deptListData = res.data;
      })
      .finally(() => {});
  }

  // 获取职务下拉列表
  getDutySelectList(groupIds) {
    getCompanyTreeData({ parentId: groupIds, types: ['2'], cascadeType: '2' })
      .then((res: Record<any, any>) => {
        this.dutyListData = res.data;
      })
      .finally(() => {});
  }
  // 获取岗位下拉列表
  getTakePostSelectList(groupIds) {
    getCompanyTreeData({ parentId: groupIds, types: ['8'], cascadeType: '2' })
      .then((res: Record<any, any>) => {
        this.takePostListData = res.data;
      })
      .finally(() => {});
  }

  /**
   * 保存
   */
  save() {
    this.personData.laborContract = this.laborContract;
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      if (this.personData.takeOffices && this.personData.takeOffices.length == 0) {
        return this.$message.error(this.$t('lang_please_enter_a_piece_of_department_information') as any);
      }

      /**
       * 判断是否有主管部门
       */
      let flag = false;
      this.personData.takeOffices.forEach(item => {
        if (item.defaultAble) {
          return (flag = true);
        }
      });
      if (!flag) {
        return this.$message.error(this.$t('lang_department_information_must_have_a_competent_department') as any);
      }
      if (!this.personData.user) {
        this.personData.user = {};
        this.personData.outType = '1'; //保留职位
      }

      this.personData.user.sort = this.personData.sort;
      this.personData.user.yearDays = this.personData.yearDays;
      this.personData.user.account = this.personData.account + this.accountSuffix;
      this.personData.user.userType = this.personData.userType;
      let params = _.cloneDeep(this.personData);
      if (JSON.stringify(params.takeAnnex) != '{}') {
        params.takeAnnex.map(item => {
          if (item && Object.keys(item).length) {
            Object.keys(item).map(keys => {
              if (keys == 'others') {
              } else if (Array.isArray(item[keys])) {
                item[keys] = item[keys][0];
              }
            });
          }
        });
        params.takeAnnex = params.takeAnnex[0];
      }
      addOrEditPerson(params)
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
            this.$emit('success');
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  /**
   * 获取部门名称
   */
  getDeptName(list, id) {
    let _this = this;
    for (let i = 0; i < list.length; i++) {
      let a = list[i];
      if (a.id === id) {
        return a.name;
      } else {
        if (a.children && a.children.length > 0) {
          let res = this.getDeptName(a.children, id);
          if (res) {
            return res;
          }
        }
      }
    }
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
