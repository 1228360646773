var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hg-100-percent", staticStyle: { "overflow-y": "auto" } },
    [
      _c(
        "glb-spliter",
        {
          attrs: {
            gutter: 10,
            spanList: [
              { span: 5, slot: "left" },
              { span: 19, slot: "right" },
            ],
          },
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "main-left-tree-wrapper" }, [
              _c(
                "div",
                { staticClass: "main-left-tree-content" },
                [
                  _c("el-tree", {
                    ref: "appTreeRef",
                    staticClass: "main-left-tree-instance",
                    attrs: {
                      data: _vm.companyData,
                      "default-expand-all": "",
                      "current-node-key": _vm.currentNodeKey,
                      "expand-on-click-node": false,
                      "highlight-current": "",
                      "node-key": "id",
                    },
                    on: { "node-click": _vm.clickTreeNode },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return [
                            _c(
                              "span",
                              { staticClass: "custom-tree-node tree-span" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "custom-tree-node-title" },
                                  [_vm._v(_vm._s(_vm.$t(data.name)))]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "page-search-bar" },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: _vm.formSearchModel,
                      inline: "",
                      "label-suffix": ": ",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("lang_department") } },
                      [
                        _c("treeselect", {
                          attrs: {
                            options: _vm.depts,
                            "append-to-body": "",
                            "z-index": 2999,
                            normalizer: _vm.treeNormalizer,
                            "default-expand-level": 1,
                            placeholder: "",
                          },
                          model: {
                            value: _vm.formSearchModel.deptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formSearchModel, "deptId", $$v)
                            },
                            expression: "formSearchModel.deptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mgb-0",
                        attrs: { label: _vm.$t("lang_user_name") },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleSearch($event)
                            },
                          },
                          model: {
                            value: _vm.formSearchModel.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formSearchModel, "name", $$v)
                            },
                            expression: "formSearchModel.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "mgb-0" },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.handleSearch },
                            }),
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-refresh",
                              },
                              on: { click: _vm.handleSearchReset },
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  disabled: _vm.showAddFlag,
                                  placement: "top",
                                  effect: "light",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "lang_please_select_the_company_on_the_left_before_adding"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        icon: "el-icon-plus",
                                        disabled: !_vm.showAddFlag,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleAdd()
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "mgb-0" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "warning",
                                  icon: "el-icon-download",
                                  size: "small",
                                },
                                on: { click: _vm.downloadTemplate },
                              },
                              [_vm._v(" 下载模版 ")]
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  disabled: _vm.showAddFlag,
                                  placement: "top",
                                  effect: "light",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [_vm._v(" 请先选择左侧公司，再进行导入 ")]
                                ),
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        ref: "upload",
                                        staticStyle: {
                                          display: "inline-block",
                                          "margin-right": "5px",
                                        },
                                        attrs: {
                                          action:
                                            "/river-system/id/person/importExcel",
                                          headers: { Authorization: _vm.token },
                                          data: {
                                            companyId: _vm.selectCompanyData.id,
                                          },
                                          name: "file",
                                          "show-file-list": false,
                                          disabled: !_vm.showAddFlag,
                                          "on-success": _vm.handleSuccess,
                                          "on-progress": function () {
                                            this$1.dLoading = true
                                          },
                                          "on-error": function () {
                                            this$1.loading = false
                                            this$1.$message.error(
                                              this$1.$t("lang_upload_fail")
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: _vm.dLoading,
                                                expression: "dLoading",
                                              },
                                            ],
                                            attrs: {
                                              icon: "el-icon-upload",
                                              type: "success",
                                              disabled: !_vm.showAddFlag,
                                              size: "small",
                                            },
                                          },
                                          [_vm._v(" 导入模版 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  background: "#fff",
                  height: "calc(100% - 62px)",
                },
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingOuter,
                        expression: "loadingOuter",
                      },
                    ],
                    staticClass: "page-pagetable",
                    attrs: {
                      border: "",
                      data: _vm.formListData,
                      height: "calc(100% - 70px)",
                      "empty-text": _vm.$t("lang_no_data"),
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_full_name"),
                        align: "center",
                        prop: "name",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_sex"),
                        align: "center",
                        prop: "sex",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.sex == "1"
                                      ? _vm.$t("lang_man")
                                      : _vm.$t("lang_woman")
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_tel_phone"),
                        align: "center",
                        prop: "phone",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_landline_number"),
                        align: "center",
                        prop: "landline",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_operation"),
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    color: "#007aff",
                                    "margin-left": "10px",
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAdd(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("lang_edit")))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("el-pagination", {
                  staticClass: "page-pager",
                  attrs: {
                    background: "",
                    "current-page": _vm.currentPage,
                    "page-sizes": [10, 20, 30, 40],
                    "page-size": _vm.pageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                  },
                  on: {
                    "size-change": _vm.changePageSize,
                    "current-change": _vm.changePage,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm.isShowAddEdit
        ? _c("person-form", {
            attrs: {
              visible: _vm.isShowAddEdit,
              groupIds: _vm.groupIds,
              selectCompanyData: _vm.selectCompanyData,
              currtent: _vm.currtent,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isShowAddEdit = $event
              },
              success: _vm.getPersonPage,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }