import HttpUtil from '@/utils/HttpUtil';

export default {
  // 上传文件
  upload(params?: any) {
    var url = '/river-fs/file/create';
    return HttpUtil.upload(url, params);
  },

  //下载文件
  download(params?: any) {
    let url = '/river-fs/file/download';
    return HttpUtil.download(url, params);
  },

  //查询文件详情
  query(params?: any) {
    let url = '/river-fs/file/query/single';
    return HttpUtil.get(url, params);
  },

  //获取文件列表
  list(params?: any) {
    let url = '/river-fs/file/query/list';
    return HttpUtil.post(url, params);
  },
  //预览文件
  preview(params?: any) {
    let url = '/river-fs/file/view';
    return HttpUtil.get(url, params);
  },

  //获取文件中的域
  getFileFields(params?: any) {
    let url = '/river-fs/word/query/filed/keys';
    return HttpUtil.get(url, params);
  },

  //签章
  seal(params?: any) {
    let url = '/river-fs/word/insert/image';
    return HttpUtil.post(url, params);
  }
};
