import HttpUtil from '@/utils/HttpUtil';

export const getMenus = (params?: any) => {
  var url = '/river-system/login/identity';
  return HttpUtil.get(url, params);
};
export const getSingle = (params?: any) => {
  var url = '/river-system/form/query/single';
  return HttpUtil.get(url, params);
};
export const getSingleData = (params?: any) => {
  var url = '/river-system/form/model/query/initial/data';
  return HttpUtil.post(url, params);
};
export const deleteFileItem = (params?: any) => {
  var url = '/river-system/form/data/delete';
  return HttpUtil.get(url, params);
};
export const personListReSetPassword = (params?: any) => {
  var url = '/river-system/id/user/reset/password';
  return HttpUtil.get(url, params);
};
