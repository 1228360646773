var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "80%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        top: "5vh",
        "append-to-body": "",
        title: _vm.fileRow ? "编辑附件信息" : "新增附件信息",
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.fileData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-width": "200px",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "简历" } },
                        [
                          _c("ele-upload", {
                            key: "1",
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: { limit: 1 },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.resume,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "resume", $$v)
                              },
                              expression: "fileData.resume",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "offer letter签字版" } },
                        [
                          _c("ele-upload", {
                            key: "2",
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: { limit: 1 },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.offerLetter,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "offerLetter", $$v)
                              },
                              expression: "fileData.offerLetter",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "原单位离职证明" } },
                        [
                          _c("ele-upload", {
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: { limit: 1 },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.leavingCert,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "leavingCert", $$v)
                              },
                              expression: "fileData.leavingCert",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "劳动合同" } },
                        [
                          _c("ele-upload", {
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: { limit: 1 },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.laborContract,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "laborContract", $$v)
                              },
                              expression: "fileData.laborContract",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "身份证正面" } },
                        [
                          _c("ele-upload", {
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: { limit: 1 },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.idCardFront,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "idCardFront", $$v)
                              },
                              expression: "fileData.idCardFront",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "身份证反面" } },
                        [
                          _c("ele-upload", {
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: { limit: 1 },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.idCardBack,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "idCardBack", $$v)
                              },
                              expression: "fileData.idCardBack",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "学位证" } },
                        [
                          _c("ele-upload", {
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: { limit: 1 },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.degree,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "degree", $$v)
                              },
                              expression: "fileData.degree",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "毕业证" } },
                        [
                          _c("ele-upload", {
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: { limit: 1 },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.diploma,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "diploma", $$v)
                              },
                              expression: "fileData.diploma",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "银行卡" } },
                        [
                          _c("ele-upload", {
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: { limit: 1 },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.bankCard,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "bankCard", $$v)
                              },
                              expression: "fileData.bankCard",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "1寸照片（电子）" } },
                        [
                          _c("ele-upload", {
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: { limit: 1 },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.oneInchPhoto,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "oneInchPhoto", $$v)
                              },
                              expression: "fileData.oneInchPhoto",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "体检报告" } },
                        [
                          _c("ele-upload", {
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: { limit: 1 },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.medicalReport,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "medicalReport", $$v)
                              },
                              expression: "fileData.medicalReport",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "其他" } },
                        [
                          _c("ele-upload", {
                            key: "9",
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            on: { success: _vm.uploadSuccess },
                            model: {
                              value: _vm.fileData.others,
                              callback: function ($$v) {
                                _vm.$set(_vm.fileData, "others", $$v)
                              },
                              expression: "fileData.others",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }